import Cookies from 'js-cookie';
import config from 'config';
import CryptoJS from 'crypto-js';

export const AUTH_SESSION_KEY = 'mizar_user';
export const REFRESH_AUTH_SESSION_KEY = 'mizar_user_is_refreshing';
export const cookies = Cookies.withConverter({
  read(value, name) {
    if (name === REFRESH_AUTH_SESSION_KEY) {
      return Cookies.converter.read(value, name);
    }
    if (name === AUTH_SESSION_KEY) {
      const cookie = Cookies.converter.read(value, name);
      const bytes = CryptoJS.AES.decrypt(cookie, config.ENCRYPTION_SEED);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(originalText);
    } else {
      const cookie = Cookies.converter.read(value, name);
      return JSON.parse(cookie);
    }
    return Cookies.converter.read(value, name);
  },
  write(value, name) {
    if (name === REFRESH_AUTH_SESSION_KEY) {
      return value;
    } else if (name === AUTH_SESSION_KEY) {
      return CryptoJS.AES.encrypt(
        JSON.stringify(value),
        config.ENCRYPTION_SEED
      ).toString();
    } else {
      return JSON.stringify(value);
    }
  },
});
