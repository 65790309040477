import { DexRoute } from 'modules/DexSwitch/DexRoute';
import { PrivateRoute, lazyWithRetry } from 'modules/routing';
import { Redirect, Route } from 'react-router-dom';
import TradeSuccesful from './TradeSuccess';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import config from '../../config';

const Research = lazyWithRetry(() => import('./research'));
const MarketplaceDetails = lazyWithRetry(
  () => import('./research/components/TelegramChannels/[traderId]/index')
);

const WalletDetails = lazyWithRetry(
  () => import('./research/components/Wallets/[address]/index')
);
const TokenDetails = lazyWithRetry(() => import('./research/[address]/index'));
const TradeDetails = lazyWithRetry(() => import('./trade-monitor/[id]/index'));
const Trade = lazyWithRetry(() => import('./trade'));
const EditTrade = lazyWithRetry(() => import('./trade/Edit/Edit'));
const Wallets = lazyWithRetry(() => import('./wallets'));
const SnipeContainer = lazyWithRetry(() => import('./trade/snipeContainer'));
const TradeMonitor = lazyWithRetry(() => import('./trade-monitor'));
const Rewards = lazyWithRetry(() => import('./rewards'));
const CreateBot = lazyWithRetry(() => import('./bots/copy-trading/CreateBot'));
const CreateVolatilityBot = lazyWithRetry(
  () => import('./bots/volatility/NewBot')
);
const EditBot = lazyWithRetry(
  () => import('./bots/copy-trading/Edit/edit-bot')
);
const DuplicateBot = lazyWithRetry(
  () => import('./bots/copy-trading/Edit/duplicate-bot')
);

const EditVolatilityBot = lazyWithRetry(
  () => import('./bots/volatility/Edit/edit-bot')
);
const DuplicateVolatilityBot = lazyWithRetry(
  () => import('./bots/volatility/Edit/duplicate-bot')
);
const MobileDexNavPage = lazyWithRetry(() => import('./MobileDexNavPage'));
const DexBots = lazyWithRetry(() => import('./bots/dashboard'));
const DuplicatePosition = lazyWithRetry(() => import('./duplicate-position'));
const Search = lazyWithRetry(() => import('./search/searchPage'));

export const dexRoutes = [
  {
    path: '/dex/navigation',
    name: 'Dex Navigation',
    exact: true,
    component: MobileDexNavPage,
    route: Route,
  },

  {
    path: '/dex/bots',
    name: 'Bots',
    exact: true,
    component: DexBots,
    route: Route,
  },

  {
    path: '/dex/research/wallet/:chain/:address/details',
    name: 'Wallet Research Details',
    component: WalletDetails,
    exact: true,
    route: Route,
  },

  {
    path: '/dex/research/telegram-channel/:traderId/details',
    name: 'Dex Marketplace Details',
    component: MarketplaceDetails,
    route: Route,
  },

  {
    path: '/dex/research/:chain/:address',
    name: 'Dex Research',
    component: TokenDetails,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/research',
    name: 'Dex Research',
    component: Research,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/wallets',
    name: 'Wallets',
    component: Wallets,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/snipe',
    name: 'Snipe',
    component: SnipeContainer,
    route: Route,
    exact: true,
  },
  {
    path: '/dex/trade-monitoring',
    name: 'Snipe',
    component: TradeMonitor,
    route: Route,
    exact: true,
  },
  {
    path: '/dex/trade-monitoring/:id',
    name: 'Trade details',
    component: TradeDetails,
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/dex/snipe/new/:chain/:address',
    name: 'Trade',
    component: Trade,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/snipe/success',
    name: 'Trade Success',
    component: TradeSuccesful,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/snipe/:chain/:positionId/edit',
    name: 'Trade',
    component: EditTrade,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/snipe/:chain/:positionId/copy',
    name: 'Trade',
    component: DuplicatePosition,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/bots/new/volatility-bot',
    name: 'Create Bot',
    component: CreateVolatilityBot,
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/dex/bots/new',
    name: 'Create Bot',
    component: CreateBot,
    route: PrivateRoute,
    exact: true,
  },

  {
    path: '/dex/bots/:id/edit',
    name: 'Edit bot',
    component: EditBot,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/bots/:id/duplicate',
    name: 'Duplicate bot',
    component: DuplicateBot,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/bots/volatility/:id/edit',
    name: 'Edit bot',
    component: EditVolatilityBot,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/bots/volatility/:id/duplicate',
    name: 'Duplicate bot',
    component: DuplicateVolatilityBot,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/search',
    name: 'Search',
    component: Search,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/rewards',
    name: 'Rewards',
    component: Rewards,
    exact: true,
    route: PrivateRoute,
  },
  // It might be never visible
  {
    path: '/dex',
    name: 'Dex',
    exact: true,
    component: function Component() {
      const isDesktop = useMediaQuery(screenGte.large);

      return (
        <Redirect
          to={
            isDesktop
              ? '/dex/snipe'
              : config.isTelegramBrowser
              ? '/dex/navigation'
              : 'dex/wallets'
          }
        />
      );
    },
    route: Route,
  },
].map((route) => ({ ...route, route: DexRoute(route.route) }));
